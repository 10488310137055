import React, { useState, useRef, useEffect } from 'react'
import { addDoc, collection,  doc, getDoc } from 'firebase/firestore';
import ButtonWrapper from '../components/paypal/ButtonWrapper';
import Spinner from '../components/Spinner'
import { db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import Bannercenter from '../components/Bannercenter'
import Features from '../components/Features'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GalleryBanner from '../components/GalleryBanner';
import './/home.css'
import BannerTrusted from "../components/BannerTrusted"
import SectionHowItWork from '../components/SectionHowItWork';
import SectionFaqs from '../components/SectionFaqs';
import SectionTestimonial from '../components/SectionTestimonial';
import Fab from '@mui/material/Fab';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import SectionServices from '../components/SectionServices';
import imageBanner from "../assets/svg/bannergwd-negrita.svg"
import logoHead from "../assets/images/logo-gwd-head512.png"


export default function Home() {

  const form = useRef();
  const navigate = useNavigate()
    const [listing, setListing] = useState(null)
    const code = process.env.REACT_APP_GWD   
    
    useEffect(() => {
        async function fetchListing() {
            const docRef = doc(db, "contents",code)
            const docSnap = await getDoc(docRef)
            if(docSnap.exists()){
                setListing(docSnap.data())
            }
        }
        fetchListing()
        
    },[])
  const [loading, setLoading] = useState(false)

  const[ showSpanish, setShowSpanish] = useState(true)


  const [changelabelLenguage, setChangeLabelLenguage] = useState("English")

  function name() {
    if(showSpanish === true){
      setShowSpanish(false)
      setChangeLabelLenguage("Spanish")
    }
    if(showSpanish === false){
      setShowSpanish(true)
      setChangeLabelLenguage("English")
    }
    
  }

  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [describe, setDescribe] = useState("")
  const [dateApt, setDateApt] = useState("")
  
  function myChangesetFullName(event) { setFullName(event.target.value)}
  function myChangesetEmail(event) { setEmail(event.target.value)}
  function myChangesetPhoneNumber(event) { setPhoneNumber(event.target.value)}
  function myChangesetCompanyName(event) { setCompanyName(event.target.value)}
  function myChangesetDescribe(event) { setDescribe(event.target.value)}
  function myChangesetDateApt(event) { setDateApt(event.target.value)}



  const [sendingMail, setSendingMail] = useState(false);

  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  const[show, setShow] = useState(false)
  const[label, setLabel] = useState(showSpanish ? "Compra Ahora" : "Buy Now")
  
  function changeLabel() {
    setShow(true)
    setLabel(showSpanish ? "Elige una opcion" : "Choose an option")
  }

    async function onSubmit(e) {
      e.preventDefault();
      setLoading(true)      

      const formDataCopy = {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        companyName: companyName,
        describe: describe,
        dateApt: dateApt,
        date: new Date().toLocaleString() + "",
      }
      
      const docRef = await addDoc(collection(db, "customers"), formDataCopy);      
      setLoading(false)
      toast.success("Message sent successfully!")
      // window.location.reload(false);
      // setLoading(false)
      // toast.success("Message sent successfully!")
      // window.location.reload(false);
      // window.location = "/"
      navigate(`/get-suscription`)
  }

  if(loading){
      return <Spinner />
  }


  return (
    <>    
    <section className="overflow-hidden bg-gray-900">        
        <div className="mt-10 relative isolate overflow-hidden pt-14">
        <img
        src={imageBanner}
          alt="Gotta Web Design LLC"
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>        
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">             
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                    <Fab variant="extended" color='secondary' size='small'  onClick={name}>
                      <GTranslateIcon sx={{ mr: 1 }} />
                      {changelabelLenguage}
                    </Fab>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-100 sm:text-6xl">
                   {
                    showSpanish ? 
                    "Crearemos un sitio web impresionante para usted en 48 horas, con un precio de solo $49 por mes"
                    : "We'll Create an Impressive Website for You Within 48 Hours, Priced at Just $49 per Month"
                   }    
                  </h1>
                  
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none ">       
                  <span className="relative flex h-3 w-3 ">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                  </span> 
                    {showSpanish ? "Sin costos iniciales ni contratos" : "No upfront costs or contracts"}                  
                  </p>
                  <p className="mt-6">
                    <Link to="/payment-suscription" className="text-lg font-semibold leading-6 text-sky-400">
                      Buy Now <span aria-hidden="true" className='animate-ping'>→</span>
                    </Link>
                  </p>
                </div>
                <div className='w-full max-w-xl lg:shrink-0 xl:max-w-2xl'>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <form className="space-y-6"  id='contact-form1'  onSubmit={onSubmit} >
                    <div>
                      <label htmlFor="fullName" className="block text-sm font-medium leading-6 text-white">
                        { showSpanish ? "Nombre completo" : "Full Name" }
                        
                      </label>
                      <div className="mt-2">
                        <input
                          id='fullName'
                          name="fullName"
                          type="text"
                          value={fullName}
                          onChange={myChangesetFullName}
                          autoComplete="Full Name"
                          required
                          className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                      { showSpanish ? "Dirección de correo electrónico" : "Email address" }
                         
                      </label>
                      <div className="mt-2">
                        <input
                          id='email'
                          name="email"
                          type="email"
                          value={email}
                          onChange={myChangesetEmail}
                          autoComplete="email"
                          required
                          className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-white">
                      { showSpanish ? "Número de teléfono" : "Phone Number" }
                        
                      </label>
                      <div className="mt-2">
                        <input
                          id='phoneNumber'
                          name="phoneNumber"
                          type="number"
                          value={phoneNumber}
                          onChange={myChangesetPhoneNumber}
                          autoComplete="Phone Number"
                          required
                          className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="companyName" className="block text-sm font-medium leading-6 text-white">
                      { showSpanish ? "nombre de empresa" : "Company Name" }
                        
                      </label>
                      <div className="mt-2">
                        <input
                        id='companyName'
                          name="companyName"
                          type="text"
                          value={companyName}
                          onChange={myChangesetCompanyName}
                          autoComplete="Full Name"
                          required
                          className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="describe" className="block text-sm font-medium leading-6 text-white">
                      { showSpanish ? "Describe tu empresa" : "Describe Your Company" }
                        
                      </label>
                      <div className="mt-2">
                        <textarea
                          id='describe'
                          name="describe"
                          rows={5}
                          value={describe}
                          onChange={myChangesetDescribe}
                          required
                          className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>    

                    <div className="space-y-5">
                       <div className="relative flex items-start">
                         <div className="flex h-6 items-center">
                           <input
                             id="checKShow"
                             aria-describedby="apt-check"
                             name="checKShow"
                             checked={checked}
                             onChange={handleChange}
                             type="checkbox"
                             className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                           />
                         </div>
                         <div className="ml-3 text-sm leading-6">
                           <label htmlFor="comments" className="font-medium text-gray-200">
                           { showSpanish ? "Hablar con un representante" : "Talk to a representative" }
                           </label>
                          {
                            checked ? (
                              <>
                             
                              <div>
                                 <label htmlFor="dateTime" className="block text-sm font-medium leading-6 text-white">
                                   { showSpanish ? "Fecha" : "Date"}
                                 </label>
                                 <div className="mt-2">
                                   <input
                                    id='dateTime'
                                     name="dateTime"
                                     type="datetime-local"
                                     value={dateApt}
                                     onChange={myChangesetDateApt}
                                     required
                                     min="2023-09-07T00:00"
                                     max="2024-09-14T00:00"
                                     className="form-control block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                   />
                                 </div>
                               </div>
                              </>
                            ) : ""
                          }
                         </div>
                       </div>
                    </div>


                    <div>
                      <button
                      id='submit-btn'
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                         {sendingMail ? (
                          <>
                            <span
                              role="status"
                              aria-hidden="true"
                              className="spinner-border spinner-border-sm align-self-center me-2"
                            ></span>
                            Sending.....
                          </>
                        ) : (
                          <>Send Message</>
                        )}
                      </button>
                      <ToastContainer />
                    </div>
                  </form>
                </div>
                

                </div>
              </div>
            </div>
            </div>
    </section>
    
    <SectionHowItWork showSpanish={showSpanish} />
    <Bannercenter showSpanish={showSpanish} />   
    <GalleryBanner  showSpanish={showSpanish} data={listing ? listing.gallery : [] } />      
    <BannerTrusted showSpanish={showSpanish}  />  
    <Features showSpanish={showSpanish}  />  
    <SectionFaqs showSpanish={showSpanish} />            
    <SectionTestimonial  showSpanish={showSpanish}  />

    <section className="flex bg-gray-900 min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src= {logoHead}
              alt="Gotta Web Design LLC"
            />
            <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-white">
             {showSpanish ? "Obtener una Suscripción" : "Get a Suscription"} 
            </h2>
            <h2 className="mt-2 text-center font-semibold leading-6 text-sky-400 hover:text-sky-300">
            $ 49.00
            </h2>            
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">           
          <div >          
          <button 
          onClick={changeLabel} 
          type='button'
          className="flex mb-10 w-full justify-center rounded-md bg-sky-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >{label}</button>
          {
            show ? (
            <ButtonWrapper type="subscription" />
            ) : <h3> </h3>
          }
          </div>
          </div>
    </section>
    <SectionServices showSpanish={showSpanish} />        
    </>
  )
}


import sara from "../assets/images/sara.png"
import lisa from "../assets/images/lisa.png"
import emely from "../assets/images/emely.png"
import david from "../assets/images/david.png"
import carlos from "../assets/images/carlos.png"



  
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
  export default function SectionTestimonial({showSpanish}) {

    const featuredTestimonial = {
      body: 'Gotta Web Design LLC exceeded my expectations! My website looked amazing from the start, and their team was quick to make the revisions I needed. Highly recommended!',
      author: {
      name: 'Sarah Johnson',
      handle: 'SarahDesigns',
      imageUrl: sara
    },
  }

  const featuredTestimonial1 = {
        body:  '¡Gotta Web Design LLC superó mis expectativas! Mi sitio web lucía increíble desde el principio, y su equipo fue rápido para hacer las revisiones que necesitaba. ¡Altamente recomendado!',
        author: {
        name:    'Sarah Johnson',
        handle:  'SarahDesigns',
        imageUrl: sara
    },
  };

  const testimonials = [
    [
      [
        {
          body: 'I opted for Gotta Web Design LLC for my project, and it was the best decision. Their technical support and their ability to translate my ideas into a professionally looking website were exceptional.',
          author: {
            name: 'David Martinez',
            handle: 'DavidTechPro',
            imageUrl: david
          },
        },
        // More testimonials...
      ],
      [
        {
          body: 'I can not thank Gotta Web Design LLC enough. They helped me take my business online, and the result was impressive. My clients love my new website.',
          author: {
            name: 'Emily Turner',
            handle: 'EmilyCreates',
            imageUrl: emely
          },
        },
        // More testimonials...
      ],
    ],
    [
      [
        {
          body: 'The Gotta Web Design LLC team did a fantastic job. They helped me bring my vision to life, and my website is now a powerful lead generation tool.',
          author: {
            name: 'Carlos Rodríguez',
            handle: 'CarlosBizGuru',
            imageUrl: carlos
          },
        },
        // More testimonials...
      ],
      [
        {
          body: 'From design to implementation, Gotta Web Design LLC was exceptional. I am thrilled with my new website. Thank you for everything!',
          author: {
            name: 'Lisa Anderson',
            handle: 'LisaArtistry',
            imageUrl: lisa
          },
        },
        // More testimonials...
      ],
    ],
  ]

  const testimonials1 = [
    [
      [
        {
         body:   'Opté por Gotta Web Design LLC para mi proyecto, y fue la mejor decisión. Su soporte técnico y su capacidad para traducir mis ideas en un sitio web con un aspecto profesional fueron excepcionales.',
         author:{
          name:    'David Martínez',
          handle:  'DavidTechPro',
          imageUrl: david
          },
        },
        // Más testimonios...
      ],
      [
        {
          body:   'No puedo agradecer lo suficiente a Gotta Web Design LLC. Me ayudaron a llevar mi negocio en línea, y el resultado fue impresionante. Mis clientes adoran mi nuevo sitio web.',
          author:{
          name:    'Emily Turner',
          handle:  'EmilyCreates',
          imageUrl: emely
          },
        },
        // Más testimonios...
      ],
    ],
    [
      [
        {
          body:   'El equipo de Gotta Web Design LLC hizo un trabajo fantástico. Me ayudaron a dar vida a mi visión, y mi sitio web es ahora una poderosa herramienta de generación de leads.',
          author:{
          name:    'Carlos Rodríguez',
          handle:  'CarlosBizGuru',
          imageUrl: carlos
          },
        },
        // Más testimonios...
      ],
      [
        {
          body:   'Desde el diseño hasta la implementación, Gotta Web Design LLC fue excepcional. Estoy encantado con mi nuevo sitio web. ¡Gracias por todo!',
          author:{
          name:    'Lisa Anderson',
          handle:   'LisaArtistry',
          imageUrl: lisa
          },
        },
        // Más testimonios...
      ],
    ],
  ];
    return (
      <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
        <div
          className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
          aria-hidden="true"
        >
          <div
            className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#38bdf8] to-[#9089fc]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          aria-hidden="true"
        >
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#38bdf8] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-sky-400">{showSpanish ? "Testimonios" : "Testimonials"}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {showSpanish ? "Nos hemos asociado con miles de personas y empresas excepcionales." : "We have partnered with thousands of exceptional individuals and businesses."} 
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-sky-400 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            {
              showSpanish ? 
              <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-sky-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
              <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
                <p>{`“${featuredTestimonial1.body}”`}</p>
              </blockquote>
              <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
                <img
                  className="h-10 w-10 flex-none rounded-full bg-gray-50"
                  src={featuredTestimonial1.author.imageUrl}
                  alt=""
                />
                <div className="flex-auto">
                  <div className="font-semibold">{featuredTestimonial1.author.name}</div>
                  <div className="text-gray-600">{`@${featuredTestimonial1.author.handle}`}</div>
                </div>
                <img className="h-10 w-auto flex-none" src={featuredTestimonial1.author.logoUrl} alt="" />
              </figcaption>
            </figure> 
            : 
            <figure className="rounded-2xl bg-white shadow-lg ring-1 ring-sky-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
              <img
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={featuredTestimonial.author.imageUrl}
                alt=""
              />
              <div className="flex-auto">
                <div className="font-semibold">{featuredTestimonial.author.name}</div>
                <div className="text-gray-600">{`@${featuredTestimonial.author.handle}`}</div>
              </div>
              <img className="h-10 w-auto flex-none" src={featuredTestimonial.author.logoUrl} alt="" />
            </figcaption>
          </figure>
            }
            {
              showSpanish ?
            testimonials1.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                        ? 'xl:row-span-2'
                        : 'xl:row-start-1',
                      'space-y-8'
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.handle}
                        className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                      >
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                          <div>
                            <div className="font-semibold">{testimonial.author.name}</div>
                            <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            )) : 
            testimonials.map((columnGroup, columnGroupIdx) => (
              <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                {columnGroup.map((column, columnIdx) => (
                  <div
                    key={columnIdx}
                    className={classNames(
                      (columnGroupIdx === 0 && columnIdx === 0) ||
                        (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                        ? 'xl:row-span-2'
                        : 'xl:row-start-1',
                      'space-y-8'
                    )}
                  >
                    {column.map((testimonial) => (
                      <figure
                        key={testimonial.author.handle}
                        className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                      >
                        <blockquote className="text-gray-900">
                          <p>{`“${testimonial.body}”`}</p>
                        </blockquote>
                        <figcaption className="mt-6 flex items-center gap-x-4">
                          <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.author.imageUrl} alt="" />
                          <div>
                            <div className="font-semibold">{testimonial.author.name}</div>
                            <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                ))}
              </div>
            ))
            
            }
          </div>          
        </div>
      </div>
    )
  }
  

import registration from "../assets/svg/registration.svg"
import responsive from "../assets/svg/responsive.svg"
import rocket from "../assets/svg/rocket.svg"
import team from "../assets/svg/team-gwd.svg"



const stats = [
    { id: 0, name: 'Creators on the platform', value: '8,000+', ima:registration },
    { id: 1, name: 'Flat platform fee', value: '3%', ima:responsive },
    { id: 2, name: 'Uptime guarantee', value: '99.9%', ima:rocket },
    { id: 3, name: 'Paid out to creators', value: '$70M', ima: registration },
  ]
  
  export default function BannerTrusted({showSpanish}) {
    return (
      
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img
          src={team}
          alt="Gotta Web Design"
          className="absolute inset-0 -z-10 h-full w-full object-cover  "
        />
        <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
          <div
            className="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10"
            aria-hidden="true"
          >
            <div
              className="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
            <h2 className="text-base font-semibold leading-8 text-sky-400">{ showSpanish ? "Nuestro historial" : "Our track record" }</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            { showSpanish ? "Nuestro historial comprobado" : "Our Proven Track Record" }  
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300 text-justify"> {
              showSpanish ? `En Gotta Web Design LLC, nuestra trayectoria habla por sí sola. Estamos orgullosos de que miles de creadores de todo el mundo confíen en nosotros. Nuestro compromiso con la excelencia
              y ofrecer sitios web excepcionales nos ha ganado la confianza de clientes de todo el mundo. Únase a las filas de clientes satisfechos que han experimentado la
              Tengo la diferencia de Web Design LLC. Su éxito es nuestro éxito,
              y esperamos agregar su proyecto a nuestra creciente lista de logros.`
              :
              `At Gotta Web Design LLC, our track record speaks for itself. We're proud to be trusted by thousands of creators worldwide. Our commitment to excellence 
                and delivering outstanding websites has earned us the trust of clients from across the globe. Join the ranks of satisfied customers who have experienced the 
                Gotta Web Design LLC difference. Your success is our success, 
                and we look forward to adding your project to our growing list of achievements.`}
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {stats.map((stat) => (
                <div key={stat.id} className="flex-col mb-6 inline-block rounded-md bg-primary-100 p-4 text-primary">
                    <img className='w-20' src={stat.ima} alt='Gotta Web Design LLC' />
                </div>
               
            ))}
          </dl>
        </div>
      </div>
    )
  }
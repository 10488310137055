import React from 'react'
import {Gem } from "lucide-react"
import dot1 from "../assets/images/dot-p-1.png"
import dot2 from "../assets/images/dot-p-2.png"
import dot3 from "../assets/images/pet-3.png"
import dot4 from "../assets/images/pet-4.png"

export default function SectionServices({showSpanish}) {
  return (
    <section className=''>
    <div className="flex flex-col p-5 bg-zinc-950 h-full lg:h-screen">             
            <div className="relative w-full h-full"> 
                <div className="table w-full h-full">
                    <div className="table-cell align-middle">
                        <div className="container z-1">
                            <div className="absolute top-20 hidden sm:hidden md:hidden lg:block">
                                <img src={dot1} alt="Gotta Web Design LLC" className="" />
                            </div>
                            <div className="grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-4 mb-4 justify-center">                        
                                <div className="col-span-12 sm:col-span-12  md:col-span-12 lg:col-span-6 xl:col-span-6 self-center">
                                    <div className=" w-full relative mb-0 md:mb-0 lg:mb-10">                                
                                        <div className="flex-auto p-4">  
                                            <h2 className="main-title-dark">{ showSpanish ? "Servicios" : "Services"}</h2>
                                            <div className=" mb-0 md:mb-0 lg:mb-5 -mt-10">                                            
                                                <h4 className="my-1 font-semibold text-[30px] md:text-[40px] text-slate-100 dark:text-slate-200 mb-0 md:mb-0 lg:mb-5 leading-12">{ showSpanish ? "Servicios Para Su" : "Services For Your"} <span className="bg-gradient-to-r from-rose-400 via-fuchsia-500 to-sky-500 bg-bottom bg-no-repeat bg-[length:100%_6px] hover:bg-[length:100%_100%] transition-[background-size]">{showSpanish ? "Negocio" : "Business"}</span></h4>                                       
                                                <div className="">                                            
                                                    <h6 className="text-gray-500 dark:text-gray-400 text-[22px] font-medium leading-8">{showSpanish ? "Potenciando Tu Negocio con Creatividad" : "Empowering Your Business with Creative,"} <br></br>{showSpanish ? "Soluciones Digitales Eficientes y Personalizadas " : "Efficient, and Tailored Digital Solutions"}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                                <div className="col-span-12 sm:col-span-12  md:col-span-12 lg:col-span-6 xl:col-span-6 self-center">
                                    <div className=" w-full relative">                                
                                        <div className="grid grid-cols-12 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-6 items-center">
                                            <div className="col-span-12 sm:col-span-12  md:col-span-6 lg:col-span-6 xl:col-span-6">
                                                <div className="grid grid-cols-1 gap-6">
                                                    <div className="bg-sky-300/10 backdrop-blur-2xl dark:bg-gray-900  rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out">
                                                        <div className="flex-auto text-center p-6 md:p-10">
                                                            <span className="inline-flex  justify-center items-center h-[70px] w-[70px] rounded-full bg-sky-500/10 dark:bg-gray-900 border border-dashed border-sky-500/30">
                                                                <Gem className=" h-8 w-8 stroke-sky-500" />
                                                                {/* <i data-lucide="gem" className=" h-8 w-8 stroke-pink-500"></i> */}
                                                            </span>
                                                            <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-200 dark:text-slate-400">
                                                                { showSpanish ? "Creatividad" : "Creativity "}                           
                                                            </h2> 
                                                            <div className="text-center text-slate-500 text-lg">
                                                                <p>{showSpanish ?"Damos vida a tus ideas creativas, desde logotipos hasta contenido visual, destacando la singularidad de tu negocio." : "We bring your creative ideas to life, from logos to visual content, showcasing the uniqueness of your business." }</p>
                                                            </div>
                                                            {/* <div className="block mt-3">
                                                                <a href="#" className="block text-slate-400 hover:text-primary-500 font-semibold  focus:outline-none">Read More <i data-lucide="arrow-right" className="self-center inline-block ms-1 h-4 w-4"></i></a>                            
                                                            </div> */}
                                                        </div>
                                                    </div> 
                                                    <div className="bg-sky-300/10 backdrop-blur-2xl dark:bg-gray-900  rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out">
                                                        <div className="flex-auto text-center p-6 md:p-10">
                                                            <span className="inline-flex  justify-center items-center h-[70px] w-[70px] rounded-full bg-sky-500/10 dark:bg-gray-900 border border-dashed border-sky-500/30">
                                                            <Gem className=" h-8 w-8 stroke-sky-500" />
                                                                {/* <i data-lucide="gem" className=" h-8 w-8 stroke-green-500"></i> */}
                                                            </span>
                                                            <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-200 dark:text-slate-400">
                                                                {showSpanish ? "Desarrollo" : "Development"}                           
                                                            </h2> 
                                                            <div className="text-center text-slate-500 text-lg">
                                                                <p>{showSpanish ? "Creamos sitios web modernos y escalables que convierten visitantes en clientes. Confía en nosotros para destacar en línea." : "We create modern and scalable websites that turn visitors into customers. Trust us to stand out online."}</p>
                                                            </div>
                                                            {/* <div className="block mt-3">
                                                                <a href="#" className="block text-slate-400 hover:text-primary-500 font-semibold  focus:outline-none">Read More <i data-lucide="arrow-right" className="self-center inline-block ms-1 h-4 w-4"></i></a>                            
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                
                                            <div className="col-span-12 sm:col-span-12  md:col-span-6 lg:col-span-6 xl:col-span-6">
                                                <div className="grid grid-cols-1 gap-6">
                                                    <div className="bg-sky-300/10 backdrop-blur-2xl dark:bg-sky-900  rounded-md w-full relative transform translate-y-0 hover:-translate-y-4 duration-500 ease-in-out">
                                                        <div className="flex-auto text-center p-6 md:p-10">
                                                            <span className="inline-flex  justify-center items-center h-[70px] w-[70px] rounded-full bg-sky-500/10 dark:bg-gray-900 border border-dashed border-sky-500/30">
                                                            <Gem className=" h-8 w-8 stroke-sky-500" />
                                                                
                                                                
                                                                {/* <i data-lucide="gem" className=" h-8 w-8 stroke-yellow-500"></i> */}
                                                            </span>
                                                            <h2 className="font-bold text-2xl capitalize tracking-wide text-center my-4 text-gray-200 dark:text-slate-400">
                                                                { showSpanish ? "Gestión" : "Management"}                           
                                                            </h2> 
                                                            <div className="text-center text-slate-500 text-lg">
                                                                <p>{showSpanish ? "Nos encargamos de la gestión en línea, desde redes sociales hasta SEO, para que te enfoques en tu negocio." : "We handle online management, from social media to SEO, so you can focus on your business."}</p>
                                                            </div>
                                                            {/* <div className="block mt-3">
                                                                <a href="#" className="block text-slate-400 hover:text-primary-500 font-semibold  focus:outline-none">Read More <i data-lucide="arrow-right" className="self-center inline-block ms-1 h-4 w-4"></i></a>                            
                                                            </div> */}
                                                        </div>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>           
                            </div>
                            <div className="absolute bottom-10 right-[15%] hidden sm:hidden md:hidden lg:block">
                                <img src={dot3} alt="Gotta Web Design LLC" className="" />
                            </div> 
                        </div>
                    </div>
                </div>                
                
            </div>
            
        </div> 
    </section>
  )
}
